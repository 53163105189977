body,
body .ql-editor {
	font-family: hknova, "Helvetica Neue", Arial, sans-serif!important;
	font-weight: 400;
	line-height: 28px;
	font-size: 15px;
	color: #353535;
  font-display: auto;

	input,
	button,
	textarea {
		font-family: hknova, "Helvetica Neue", Arial, sans-serif!important;
		font-weight: 400;
		line-height: 28px;
		font-size: 15px;
	}

	a {
		color: $primary;
		text-decoration: none;
		cursor: pointer;
		transition: background cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.3s,
								color cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.3s;
	}

	.btn {
		font-family: hknova, "Helvetica Neue", Arial, sans-serif!important;
	}

	u {
		text-decoration: none!important;
	}

  .small,
	small {
		font-size: 80%;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6,{
		font-family: $heading-font-stack!important;
		position: relative;
		margin: 0 0 20px 0;
		color: #252525;
		font-weight: 600;
	}

	h1,
  .h1 {
		font-size: 32px;
		line-height: 40px;

		@media only screen and (max-width: 960px) {
			font-size: 30px;
			line-height: 40px;
		}

		@media only screen and (max-width: 768px) {
			font-size: 28px;
			line-height: 38px;
		}
	}

	h2,
  .h2 {
		font-size: 24px;
		line-height: 36px;

		@media only screen and (max-width: 960px) {
		font-size: 22px;
		line-height: 36px;
		}

		@media only screen and (max-width: 768px) {
		font-size: 22px;
		line-height: 34px;
		}
	}

	h3,
  .h3 {
		font-size: 20px;
		line-height: 34px;
		@media only screen and (max-width: 960px) {
			font-size: 18px;
			line-height: 32px;
		}
	}

	h4,
  .h4 {
		font-size: 18px;
		line-height: 32px;
		@media only screen and (max-width: 768px) {

		}
	}

	h5,
  .h5 {
		font-size: 16px;
		line-height: 30px;
		@media only screen and (max-width: 768px) {

		}
	}

	h6,
  .h6{
		font-size: 16px;
		line-height: 28px;
		@media only screen and (max-width: 768px) {

		}
	}

	p {
		margin: 0 0 24px 0;
	}

	strong {
		font-weight: 600;
	}
}


.mjs-heading,
main h2,
.heading {
	position: relative;
	padding-bottom: 10px;
	margin-bottom: 20px;
	clear: both;
}

.heading span {
	color: $primary;
}

main h2,
.mjs-heading,
.heading {

	&:before {
		content: "";
		display: block;
		width: 50px;
		height: 3px;
		position: absolute;
		left: 0;
		bottom: 0;
		background: $primary;
	}

	&.text-center {
		&:before {
			left: 50%;
			transform: translateX(-50%);
		}
	}
}

.mjs-social-widget {
  display: block;
  margin: 0 0 10px 0!important;
}


.faq {
	button {
		width: 100%;
		-moz-appearance: none;
		-webkit-appearance: none;
		border: none;
		display: block;
		border-radius: 3px;
		background: white;
		box-shadow: 0 3px 8px rgba(27,27,27,0.08);
		font-weight: 500;
		font-size: 16px;

		.faq-arrow {
			width: 32px;
			height: 32px;
			position: absolute;
			right: 10px;
			top: 50%;
			transform: translateY(-50%);

			i {
				position: absolute;
				top: 50%;
				left: 50%;
				width: 16px;
				height: 3px;
				background: $primary;
				transition: all .2s;

				&:first-child {
					margin: 0 0 0 -10px;
					transform: rotate(45deg);

				}

				&:last-child {
					margin: 0 -10px 0 0;
					transform: rotate(-45deg);
				}
			}
		}

		&[aria-expanded="true"] {
			i {
				&:first-child {
					transform: rotate(135deg);
				}

				&:last-child {
					transform: rotate(-135deg);
				}
			}
		}
	}
}

button,
a {
	&:focus {
		outline: none;
		box-shadow: 0 0 0 0.2rem rgba(48, 128, 145, .8) !important;
	}
}
