@use '@angular/material' as mat;

@include mat.core();

$my-primary: mat.define-palette(mat.$indigo-palette, 500);
$my-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

$my-theme: mat.define-light-theme((
 color: (
   primary: $my-primary,
   accent: $my-accent,
 )
));

@include mat.all-component-themes($my-theme);

@import "~node_modules/bootstrap/scss/mixins";
@import "~node_modules/bootstrap/scss/functions";

@import "styles/variables";
@import "~node_modules/bootstrap/scss/root";
@import "~node_modules/bootstrap/scss/reboot";

@import "~node_modules/bootstrap/scss/grid";
@import "~node_modules/bootstrap/scss/buttons";
@import "~node_modules/bootstrap/scss/utilities";
@import "~node_modules/bootstrap/scss/forms";
@import "~node_modules/bootstrap/scss/custom-forms";
@import "~node_modules/bootstrap/scss/card";
@import "~node_modules/bootstrap/scss/badge";
@import "~node_modules/bootstrap/scss/list-group";
@import "~node_modules/bootstrap/scss/alert";
@import "~node_modules/bootstrap/scss/breadcrumb";

@import '~ngx-toastr/toastr.css';
@import 'styles/reset';
@import 'styles/html-body';
@import 'styles/fonts';
@import 'styles/angular-forms';
@import 'styles/grid';
@import 'styles/rotate-animation';
@import 'styles/tables';
@import 'styles/typography';
@import 'styles/lists';
@import 'styles/sidebar';
@import 'styles/gdpr';
@import 'styles/messages';
@import 'styles/sticky-tables';
@import 'styles/parallax';
@import 'styles/tab-navigation';
@import 'styles/rac-modal';
@import 'styles/qc-dgpr-tool';
@import 'styles/call-to-action';
@import 'styles/nav-tabs-styled';
@import 'styles/mat-menu';
@import 'styles/material-overrides';
@import 'styles/quill';
