.sidebar {
	flex: 0 0 330px;
	min-width: 330px;
	max-width: 330px!important;
	padding-bottom: 30px;
}

body {
	&.full-width {
		main {
			width: 100%!important;
			max-width: 100%!important;
		}
	}
}

main {
	@media only screen and (min-width: 960px) {
		max-width: calc(100% - 330px)!important;
	}

	&:not([class*="offset"]) {
		@media only screen and (max-width: 859px) {
			width: 100%!important;
			max-width: 100%!important;
			flex: none!important;
		}
	}

	@media only screen and (max-width: 768px) {
		img {
			max-width: 100%;
			height: auto;
		}
	}
	
	padding-bottom: 30px;
}

.form-control.recaptcha-field {
	height: auto;
	border: none;
	padding: 0;
	border-radius: 0;
}

@media (min-width: 576px) {
	.container {
		max-width: 100%;
	}
}

@media (min-width: 768px) {
	.container {
		max-width: 100%;
	}
}

@media (min-width: 992px) {
	.container {
		max-width: 100%;
	}
}

@media (min-width: 1140px) {
	.container {
    max-width: 1140px;
	}
}

@media (max-width: 992px) {
	main.col {
		width: 100%;
	}

	.sidebar {
		margin-left: auto;
		margin-right: auto;
	}
}

.mjs-application-list {
	margin: 0;
	list-style-type: none;

  li {
		list-style-type: none;
    border: 1px solid #f0f0f0;
    border-radius: 3px;
    box-shadow: 0 3px 8px rgba(27,27,27,0.08);
    position: relative;
    margin: 0 0 10px 0;

    .favourite-header {
      box-shadow: 0 2px 4px rgba(27,27,27,.08);
    }

    .label {
      text-transform: uppercase;
      font-size: 11px;
    }

    .row {
      .col:not(:last-child) {
        border-right: 1px solid #f0f0f0;
      }
    }
  }

  .btn {
    cursor: pointer;
  }
}
