.modal-body {
  @import './reset';

  word-wrap: normal !important;
  white-space: normal !important;

  padding: 10px 15px;

  p,
  ul,
  ol,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 20px !important;
  }

  strong {
    font-weight: bold;
  }

  ul {
    position: relative;
    margin: 0 0 24px 36px;

    li {
      position: relative;
      list-style-type: disc;

      ul {
        li {
          list-style-type: square;

          ul {
            li {
              list-style-type: circle;
            }
          }
        }
      }
    }
  }

  ol {
    li {
        position:relative;
        list-style-type:decimal;

      ol {
        li {
          list-style-type: lower-latin;

          ol {
            li {
              list-style-type:lower-roman;
            }
          }
        }
      }
    }
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    position: relative;
    padding-bottom: 15px;
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 22px;

    &:before {
      content: "";
      display: block;
      width: 50px;
      height: 3px;
      position: absolute;
      left: 0;
      bottom: 0;
      background: $primary;
    }
  }
}

.modal-footer {
  padding: 15px;
}

.ui-dialog-title {
  font-size: 22px;
}