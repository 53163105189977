.job-description,
.wysiwyg-content {
  ul {
    position: relative;
    margin: 0 0 24px 24px;

    li {
      position: relative;
      list-style-type: disc;

      ul {
        li {
          list-style-type: square;

          ul {
            li {
              list-style-type: circle;
            }
          }
        }
      }
    }
  }

  ol {
    li {
        position:relative;
        list-style-type:decimal;

      ol {
        li {
          list-style-type: lower-latin;

          ol {
            li {
              list-style-type:lower-roman;
            }
          }
        }
      }
    }
  }

  img {
    max-width: 100% !important;
  }
}


#freeform_form {
  ol {
    margin: 0;

    li {
      list-style-type: none;
      margin: 0 0 10px 0;
      display: block;
      width: 100%;
      clear: both;
    }
  }
}

.list-unstyled,
.list-unstyled li {
  list-style-type: none !important;
  margin-left: 0 !important;
}
