.messages {
  width: 100%;
  background: #fff;
  border-radius: 3px;
  margin: 0 0 40px 0;
  padding: 12px 20px;
  font-weight: 600;
  box-shadow: 0 3px 8px rgba(27,27,27,0.08);

  &.status {
    border-left: 3px solid #da9619;
  }

  &.success {
    border-left: 3px solid #72c32b;
  }

  &.error {
    border-left: 3px solid #d6655d;
  }

  > :last-child {
      margin-bottom: 0;
  }
}
