.call-to-action {
  margin: 0 0 -30px 0;
  padding: 30px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &:before {
    content: "";
    display: block;
    width: 10000%;
    height: 100%;
    background-color: currentColor;
    position: absolute;
    top: 0;
    left: -5000%;
    z-index: -1;
  }

  > * {
    color: #fff;
    margin-bottom: 0;
  }

  p {
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    align-self: center;
  }

  &:after {
    content: none !important;
  }
}