[class^="app_gdpr"] {
  font-family: hknova!important;

  [class^="banner_banner"] {
    box-shadow: 0 -5px 10px -5px rgba(27, 27, 27, 0.08)!important;
  }

  [class^="banner_title"] {
    font-weight: 600;

    + span {
      line-height: 24px;
    }
  }

  [class^="banner_bannerLogo"] {
    display: none;
  }

  [class^="banner_consent"] {
    > a {
      border-radius: 2px;
      color: #fff!important;
      font-size: 14px;
      font-weight: 600;
      text-decoration: none;
    }
  }
}

[class^="banner_buttons"] {
  flex: none;
}
