@font-face {
	font-family: 'mjs';
	src: url('/assets/fonts/mjs/fonts/mjs.eot');
	src: url('/assets/fonts/mjs/fonts/mjs.eot?#iefix') format('embedded-opentype'),
		url('/assets/fonts/mjs/fonts/mjs.ttf') format('truetype'),
		url('/assets/fonts/mjs/fonts/mjs.woff') format('woff'),
		url('/assets/fonts/mjs/fonts/mjs.svg#mjs') format('svg');
	font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'hknova';
  src: url('/assets/fonts/hknova/HKNova-ExtraBold.woff2') format('woff2'),
    url('/assets/fonts/hknova/HKNova-ExtraBold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'hknova';
  src: url('/assets/fonts/hknova/HKNova-Thin.woff2') format('woff2'),
    url('/assets/fonts/hknova/HKNova-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'hknova';
  src: url('/assets/fonts/hknova/HKNova-Heavy.woff2') format('woff2'),
    url('/assets/fonts/hknova/HKNova-Heavy.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'hknova';
  src: url('/assets/fonts/hknova/HKNova-Regular.woff2') format('woff2'),
    url('/assets/fonts/hknova/HKNova-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'hknova';
  src: url('/assets/fonts/hknova/HKNova-SemiBold.woff2') format('woff2'),
    url('/assets/fonts/hknova/HKNova-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'hknova';
  src: url('/assets/fonts/hknova/HKNova-Medium.woff2') format('woff2'),
    url('/assets/fonts/hknova/HKNova-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'hknova';
  src: url('/assets/fonts/hknova/HKNova-Bold.woff2') format('woff2'),
    url('/assets/fonts/hknova/HKNova-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'hknova';
  src: url('/assets/fonts/hknova/HKNova-ExtraLight.woff2') format('woff2'),
    url('/assets/fonts/hknova/HKNova-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'hknova';
  src: url('/assets/fonts/hknova/HKNova-Light.woff2') format('woff2'),
    url('/assets/fonts/hknova/HKNova-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
