.sidebar-menu {
  position :relative;
  margin: 0 0 30px 0;
  border-top: 1px solid #e5e5e5;
  display: block;

  > li > a {
    border-bottom: 1px solid #e5e5e5;
    font-weight: 500;
  }

  > li > ul > li > a {
    font-weight: 500;
  }

  li {
    position: relative;

    &:last-child {
      border-bottom: none;
    }

    a {
      display: block;
      padding: 8px 0;
      position: relative;
      color: #444;
      border-bottom: 1px solid #e5e5e5;

      &:hover,
      &.active {
        color: $primary;
      }
    }

    &.active {
      > a {
        color: $primary;

        &:before {
          content: "";
          background: $primary;
          width: 3px;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
        }
      }
    }
  }

  ul ul a {
    padding-left: 20px;
    font-size: 90%;
  }


  a {
    transition: all .2s;
    font-weight: 500;

    &.active,
    &:hover {
      color: $primary;
    }
  }
}

#sidebar-ad {
  width:  100%;
  min-height: 290px;
  overflow: hidden;
  text-align: center;
  position: relative;
  margin-bottom: 20px;
}

.advert {
  overflow: hidden;
}

.sidebar-user-menu {
  position: relative;
  border: 1px solid #e5e5e5;
  border-bottom: none;
  margin-bottom: 20px;
  background: #fff;

  li {
    list-style-type: none;
    display: block;
    width: 100%;
    position: relative;

    a {
      display: block;
      padding: 12px 0 12px 60px;
      border-bottom: 1px solid #e5e5e5;
      font-weight: 600;
      color: #656565;

      &:before {
        content: "";
        display: block;
        width: 48px;
        height: 100%;
        background: #f7f7f7;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        border-bottom: 1px solid #e0e0e0
      }

      &:hover {
        background: #f0f0f0;
      }

      svg {
        width: 16px;
        height: 16px;
        position: absolute;
        left: 16px;
        top: 50%;
        margin-top: -8px;

        path {
          fill: $primary;
        } 
      }
    }
  }
}

