.nav-tabs-styled {
  > li {
    > a,
    > span {
      position: relative;

      &:before {
        content: "";
        display: block;
        height: 3px;
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 17px;
        background: #f0f0f0;
        z-index: -1;
        transition: all .2s ease-in-out;
      }

      &:hover,
      &.active,
      &:focus {
        &:before {
          background: $primary;
        }
      }

      &.active {
        ~ ul {
          display: block;
        }
      }

      > div {
        background: #fff;
        font-weight: 500;
        font-size: 90%;
      }
    }

    ul {
      display: none;
      position: relative;
      padding-left: 10px;

      &:before {
        content: "";
        display: block;
        position: absolute;
        left: 10px;
        top: -5px;
        background: $primary;
        height: calc(100% - 10px);
        width: 3px;
      }

      li {
        display: block;

        a {
          font-size: 90%;
          font-weight: 500;
          padding: 0 0 0 20px!important;
          position: relative;

          &:focus,
          &:hover,
          &.active {
            color: $primary !important;
          }

          &:before {
            content: "";
            display: block !important;
            width: 12px;
            height: 3px;
            left: 0;
            top: 50%;
            background: $primary;
            transform: translateY(-50%);
            position: absolute;
          }
        }
      }
    }
  }
}

.sidebar-menu-toggle {
  position: relative;

  .mjs-menu-toggle {
    left: auto;
    right: 10px;
    width: 30px;

    i {
      background: $primary;
    }
  }

  &:hover {
    .mjs-menu-toggle {
      i {
        background: white;
      }
    }
  }
}
